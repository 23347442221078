import { useState } from "react";
import ContentModule from "./ContentModule";
import { CallToActionModule } from "./CallToActionModule";

export const BucketModule = ({clientId, personId}) => {
  const initialFormData = {
    taxable: { input1: '' },
    taxDeferred: { input1: '' },
    taxFree: { input1: '' },
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (bucketId, inputKey, value) => {
    const newInputs = { ...formData[bucketId], [inputKey]: value };
    const allFilled = Object.values(newInputs).every(val => val.trim() !== '');
    const maxInputs = 7;  // Maximum number of inputs allowed per column
  
    // Check if all current inputs are filled and less than maximum inputs
    if (allFilled && Object.keys(newInputs).length < maxInputs) {
      const nextInputKey = `input${Object.keys(newInputs).length + 1}`;
      newInputs[nextInputKey] = ''; // Add new input if the conditions are met
    }
  
    setFormData(prevFormData => ({
      ...prevFormData,
      [bucketId]: newInputs,
    }));
  };
  
  const renderInputs = (bucket) => (
    <>
      {Object.keys(formData[bucket.id]).map((inputKey, index) => (
        <input
          id={`${bucket.id}-${index + 1}`} // Corrected ID generation
          key={inputKey}
          type="text"
          name={inputKey}
          value={formData[bucket.id][inputKey]}
          onChange={e => handleChange(bucket.id, inputKey, e.target.value)}
          placeholder={bucket.types[index] || ''}
          className="p-2 border rounded mt-2 2xs:w-full md:w-5/6" />
      ))}
    </>
  );

  const myBucketList = [
    {
      id: 'taxable',
      title: 'Taxable Account',
      description: 'Cash, Savings, CD\'s, Bonds, 1099 Income, etc.',
      types: ['Cash', 'Savings', 'CD\'s', 'Bonds', '1099 Income'],
      imageSrc: 'https://lifepro.blob.core.windows.net/lifepro-file-storage/images/emails/announcements/taxable-account.png'
    },
    {
      id: 'taxDeferred',
      title: 'Tax-Deferred Account',
      description: '401(k)s, Qualified Money, Savings Bonds, 403(b)s, etc.',
      types: ['401(k)s', 'Qualified Money', 'Savings Bonds', '403(b)s'],
      imageSrc: 'https://lifepro.blob.core.windows.net/lifepro-file-storage/images/emails/announcements/tax-deferred-account.png'
    },
    {
      id: 'taxFree',
      title: 'Tax-Free Account',
      description: 'Roth IRA, 529 Plans, Health Savings (HSA), Life Insurance',
      types: ['Roth IRA', '529 Plans', 'Health Savings Account (HSA)', 'Life Insurance'],
      imageSrc: 'https://lifepro.blob.core.windows.net/lifepro-file-storage/images/emails/announcements/tax-free-account.png'
    }
  ];
 
  function focusAndSetCursor(bucketId) {
    const bucketInputs = formData[bucketId];
    const lastInputIndex = Object.keys(bucketInputs).length;
    const inputElementId = `${bucketId}-${lastInputIndex}`;
    var inputElement = document.getElementById(inputElementId);
    inputElement.focus();
    const value = inputElement.value;
    if (value) {
        inputElement.setSelectionRange(value.length, value.length);
    }
  }

  return (
    <>
    <form id="bucketsForm" className="flex flex-row w-full ">
      <ContentModule className="flex flex-col items-center justify-center w-full"> 
        <section className="flex w-full justify-between 2xs:flex-col lg:flex-row gap-10 lg:gap-0 ">
        {myBucketList.map(bucketItem => (
          <div key={bucketItem.id} className="flex lg:flex-col items-center gap-6 border rounded p-4 lg:p-0 lg:border-none lg:gap-2 w-full hover:scale-105 focus-within:scale-105 transition-all">
           <div className="flex flex-col items-center gap-2 w-full">
              <img src={bucketItem.imageSrc} alt={bucketItem.title} className="h-auto object-cover cursor-pointer w-1/2" onClick={() => focusAndSetCursor(bucketItem.id)}/>
              <h3 className="xs:text-xl lg:text-2xl font-semibold cursor-pointer text-center" onClick={() => focusAndSetCursor(bucketItem.id)}>{bucketItem.title}</h3>
              <p className="line-clamp-4 text-center text-xs xs:text-sm md:text-lg lg:w-3/4 lg:line-clamp-2">{bucketItem.description}</p>
            </div>
            <div className="flex flex-col h-full lg:items-center gap-2 w-3/8 xs:w-full">
              {renderInputs(bucketItem)}
            </div>
          </div>
        ))}
        </section>
        {/* <button type="submit" className="bg-green-500 hover:bg-green-700 text-black font-bold py-2 px-4 rounded self-center">
        Submit
      </button> */}
      </ContentModule>
    </form>
    <CallToActionModule formData={formData} clientId={clientId} personId={personId}/>
    </>

  );
};
