import { useState, useEffect } from 'react';

const AdvisorLogo = ({ personId, alt }) => {
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
        if (personId) {
            const imageUrl = `https://lifepro.blob.core.windows.net/lifepro-file-storage/Agent%20Files/${personId}/branding/reports/logo.png`;
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => setImageExists(true);
            img.onerror = () => setImageExists(false);
        }
    }, [personId]);

    if (!personId || !imageExists) return null;

    const imageUrl = `https://lifepro.blob.core.windows.net/lifepro-file-storage/Agent%20Files/${personId}/branding/reports/logo.png`;

    return (
        <img className="w-auto object-cover 2xs:max-h-12 xs:max-h-20" src={imageUrl} alt={alt} />
    );
};

export default AdvisorLogo;
