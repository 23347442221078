import ContentModule from "./ContentModule";

export const Hero = () => {
  function downloadWorksheet() {
    window.open('https://lifepro.blob.core.windows.net/lifepro-file-storage/images/emails/announcements/paying-taxes.png', '_blank');
  }
  return (<>
    <ContentModule className='flex items-start justify-between'>
      <aside className='lg:w-7/12 flex flex-col start '>
        <h1 className='font-bold 2xs:text-2xl xs:text-3xl sm:text-4xl '>Take The Tax Challenge</h1>
        <h2 className='2xs:text-md xs:text-lg sm:text-2xl text-indigo-800 mb-2'>How does your retirement portfolio stack up?</h2>
        <span className="2xs:text-md md:text-xl font-light text-gray-500">Fill out the “3 Buckets of Money” Worksheet to gain a comprehensive view of your tax diversification. Then click “Submit” to get your completed PDF sent to your inbox!</span>
      </aside>

      <div className='hidden lg:block w-5/12 max-h-60 object-contain'><img className="object-contain h-auto max-w-72" src='https://lifepro.blob.core.windows.net/lifepro-file-storage/images/emails/announcements/paying-taxes.png' />
        </div> 
    </ContentModule>
  </>);
};
