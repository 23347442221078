import OfferCodeImage from "./offer.png";

const Page_InputOfferCode = ({onClick, loading}) => {
  const getCodeFromInput = () => document.getElementById('offerCode').value;

  return ( 
    <>
    <div className="w-screen h-screen flex justify-center bg-[#f8f7fa]">
      <div className="w-full max-w-lg my-0 flex justify-start items-center flex-col 2xs:mx-4 lg:mx-auto">
        <img src={OfferCodeImage} className="w-1/4 my-4 xs:my-16"/>
        <div className="flex flex-col items-center gap-4 mb-4 xs:mb-16">
          <h1 className="font-bold text-center 2xs:text-2xl xs:text-4xl ">Enter Your Offer Code</h1>
          <p className="text-center">To obtain the special offer that was presented, please enter the code you were provided and click the button to continue.</p>
          <input id="offerCode" type="text" className="w-1/2 p-4 border-2 border-gray-300 rounded-md 2xs:text-sm xs:text-xl" placeholder="Enter your offer code"  onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onClick(getCodeFromInput(), null, null);
            }
          }}/>
          <button className="bg-green-500 active:bg-green-400 hover:bg-green-600 text-white p-4 rounded-md w-1/2 2xs:text-sm xs:text-lg font-bold" onClick={() => onClick(getCodeFromInput(), null, null)}>{loading ? 'Please wait...' : 'CONTINUE' }</button>
        </div>
        <p className="text-gray-600 text-sm text-center"><span className="font-bold">Need help?</span> Contact the financial professional who directed you to this page.</p>
      </div>
    </div>
    </>
  );
}

export default Page_InputOfferCode;