import ContentModule from "./ContentModule";
import AdvisorLogo from "./AdvisorLogo";
import Phone from "./Phone";

const Header = ({phoneNumber, personId, contact}) => {
    return (
      <header className="h-24 border-gray-200 border-b-2 ">
        <ContentModule className={"flex justify-between items-center h-24"}>
          <AdvisorLogo personId={personId} alt={contact} />
          <div className='flex items-center 2xs:text-sm xs:text-xl'>
            <Phone />{' ' + phoneNumber}
          </div>
        </ContentModule>
      </header>
    );
  };

  export default Header;