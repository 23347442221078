import ContentModule from "./ContentModule";

export const AdvisorBio = ({ personId, advisorTitle, companyName, phoneNumber, emailAddress, contact, bio }) => {

  const imageUrl = `https://lifepro.blob.core.windows.net/lifepro-file-storage/Agent%20Files/${personId}/branding/reports/profile.png`;
  return (
    <>
    <h3 className='font-bold text-2xl flex justify-center text-center'>
    Have any questions? Contact me today!</h3>
      <ContentModule className='flex items-start gap-4'>
        <div className='w-1/3 flex flex-col items-end'>
          {personId ? <img src={imageUrl} className='w-1/2 h-auto object-cover' /> : false}
        </div>
        <div className='flex flex-1 flex-col gap-4'>
          <p>
            <span className='font-bold'>{contact}</span><br />
            {advisorTitle}<br />
            {phoneNumber} | {emailAddress}
          </p>
          <Bio bio={bio} contact={contact}/>
        </div>
      </ContentModule>
    </>
  );
};


const Bio = ({ bio, contact }) => {
  if (bio) {
    return <p dangerouslySetInnerHTML={{ __html: bio }} />;
  }
  return (
    <>
      <p>As an independent financial professional and an active member of the community, {contact} is dedicated to helping individuals, families, and businesses attain peace of mind and financial freedom.</p>
      <p>For years, they studied the characteristics of wealth accumulation vehicles and has come to the conclusion that there is an underlying commonality among the individuals who are best positioned for financial success. All of these individuals have been educated on the financial principles of how to grow, protect, and transfer wealth.</p>
    </>
  );
};
