import { useState, useEffect } from "react";
import Loader from "./Loader";
import Header from "./Header";
import { Hero } from "./Hero";
import { Footer } from "./Footer";
import { AdvisorBio } from "./AdvisorBio";
import { BucketModule } from "./BucketModule";
import Page_LoadFailed from "./Page_LoadFailed";
import Page_NotAuthorized from "./Page_NotAuthorized";
import Page_InputOfferCode from "./Page_InputOfferCode";


export default function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [personId, setPersonId] = useState('');
  const [clientId, setClientId] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [contact, setContact] = useState('');
  const [advisorTitle, setAdvisorTitle] = useState('');
  const [licenseInfo, setLicenseInfo] = useState('');
  const [isLackingIdentifiers, setIsLackingIdentifiers] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [isDnc, setIsDnc] = useState(false);
  const [bio, setBio] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingFailed, setFailed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const fetchData = async (code, clientIdKey, personIdKey) => {
    if(loaded) return;
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      let key;
      let url;
      setLoading(true);
      if(code){
        key = process.env.REACT_APP_ADVISOR_INFO_BY_CODE_KEY;
        url = `${baseUrl}/code/${code}`;
        console.log('url ', url);
      }else if(clientIdKey) {
        key = process.env.REACT_APP_ADVISOR_INFO_BY_CLIENT_KEY;
        url = `${baseUrl}/client/${clientIdKey}`;
        console.log('url ', url);

      }else if(personIdKey){
        key = process.env.REACT_APP_ADVISOR_INFO_KEY;
        url = `${baseUrl}/${personIdKey}`;
        console.log('url ', url);
      }else{
        setFailed(true);
        return;
      }

      const fetchOptions = {
        method: 'GET', // or 'POST' depending on your API requirement
        headers: {
          'Content-Type': 'application/json',
          'X-Functions-key': key,
          'X-Api-Key': key,
        }
      };
      const response = await fetch(url,fetchOptions);

      if (!response.ok) {
        throw new Error('Network response was not ok ', response.status, response.statusText);
      }
      
      const data = await response.json();
      if(data.IsDnc){
        setIsDnc(true);
        setLoaded(true);
        return;
      }

      setIsLackingIdentifiers(false);

      //  console.log(data);
       setPersonId(data.PersonId);
       setPhoneNumber(data.PhoneNumber);
       setAddress(data.Address);
       setEmailAddress(data.EmailAddress);
       setWebsite(data.Website);
       setContact(data.Contact);
       document.title = `Tax Challenge | ${data.Contact}`;
       setAdvisorTitle(data.AdvisorTitle || 'Wealth Coach'); // Using a fallback if no title is provided
       setLicenseInfo(data.LicenseInfo);
       setBio(data.AdvisorBio);
       if(data.ClientInfo?.EmailAddress){
          setClientEmail(data.ClientInfo.EmailAddress);
       }

    } catch (error) {
      setFailed(true)
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };
  
  useEffect(() => {
    if(loaded) return;

    const params = new URLSearchParams(window.location.search);

    console.log('params: ', params.toString());

    var currClientId = params.get('clientid') || params.get('clientId') || params.get('clientID') || params.get('CLIENTID');
    var currPersonId = params.get('personid') || params.get('personId') || params.get('personID') || params.get('PERSONID');
    
    
    setIsLackingIdentifiers(!currClientId && !currPersonId);
    if(currClientId){
      setClientId(currClientId);
    }
    if(currPersonId){
      setPersonId(currPersonId);
    }

    // an API call
    // console.log(clientId);
    // console.log(personId);
    if(currPersonId == 413264){
      setPhoneNumber('(888) 543 - 3776');
      setPersonId('413264');
      setEmailAddress('info@lifepro.com');
      setWebsite('https://www.lifepro.com/');
      setContact('Sample Agent');
      document.title = `Enter Your Offer Code`;

      setAdvisorTitle('Wealth Coach');
      setAddress({
        Address1: '11512 El Camino Real',
        City: 'San Diego',
        State: 'CA',
        Zip: '92130'
      });
      setLicenseInfo('CA Lic. #0K24302');
    }else if(currClientId){
      fetchData(null, currClientId, null);
    }else if(currPersonId){
      fetchData(null, null, currPersonId);
    }
    
  }, []);

  

  if (loadingFailed) {
    return (
      <Page_LoadFailed/>
    )
  }

  if(isDnc) {
    return (
      <Page_NotAuthorized/>
    );
  }

  if(isLackingIdentifiers) {
    return (
      <Page_InputOfferCode onClick={fetchData} loading={loading}/>
    );
  }

  if (!isLackingIdentifiers  && loading) {
    return (
      <>
      <Header phoneNumber={phoneNumber} personId={personId} contact={contact}/>
      <div>
        <Loader />
      </div>
      </>
    );
  } 

  return (
    <main className='flex flex-col gap-10'>
      <Header phoneNumber={phoneNumber} personId={personId} contact={contact}/>
      <Hero />
      <BucketModule clientId={clientId} personId={personId}/>
      <AdvisorBio personId={personId} advisorTitle={advisorTitle} companyName='LifePro Asset Management' phoneNumber={phoneNumber} emailAddress={emailAddress} contact={contact} bio={bio}/>
      <Footer licenseInfo={licenseInfo} address={address} emailAddress={emailAddress} website={website} phoneNumber={phoneNumber}/>
    </main>
  );
}


