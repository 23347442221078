const Page_NotAuthorized = () => {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-[#f8f7fa]">
      <div className="p-4 max-w-sm rounded overflow-hidden shadow-lg bg-white border border-red-200">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 ">Access Denied</div>
          <p className="text-base">
            Sorry, you're not authorized to view this page.
          </p>
        </div>
      </div>
    </div>
    );
}

export default Page_NotAuthorized;