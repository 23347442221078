import ContentModule from "./ContentModule";

export const Footer = ({ licenseInfo, address, phoneNumber, emailAddress, website }) => {
  const phoneSection = (phoneNumber ? <p>Ph: {phoneNumber}</p> : <></>);
  const emailSection = (emailAddress ? <p>Em: {emailAddress}</p> : <></>);
  const addressSection = (address.City && address.State ? <p>{address.Address1}<br />{address.City}, {address.State} {address.Zip}</p> : <></>  );
  return (
    <footer className="bg-[#222222] text-white border-gray-200 border-t-2 py-6 text-sm mt-4">
      <ContentModule className={"flex  sm:justify-between sm:items-center gap-12 2xs:flex-col sm:flex-row"}>
        <div className='flex flex-col start text-[#c0c0c0] gap-4'>
          <p className='text-white font-semibold'>Disclosure</p>
          <p>Projections and information in the report regarding potential outcomes are hypothetical and are not intended to reflect actual results nor guarantee future results. Results may be more or less favorable due to uncontrollable future data such as investment returns, inflation rates, tax rates, and product expenses.</p>
          <p>The information presented is not securities related and the topics presented do not participate in the stock market nor are stock market investments. Any guarantees within the Wealth Report® are based on insurance carrier illustration(s) which are backed by the claims paying ability of the insurance company and are subject to change. Other sales charges or other expenses may be required for some policies.</p>
          <p>The content of the report is not intended to provide legal, tax, or accounting advice. Please consult your tax advisor for specific tax advice.</p>
          <p>{licenseInfo}</p>
        </div>
        <div className='flex flex-col gap-4 font-semibold text-nowrap'>
          <p>Contact Me</p>
          {addressSection}
          {phoneSection}
          {emailSection}
          <p>{website}</p>
        </div>
      </ContentModule>
    </footer>
  );
};

