const Page_LoadFailed = () => {
    return (
        <div className="w-screen h-screen flex justify-center items-center bg-[#f8f7fa]">
        <div className="p-4 max-w-sm rounded overflow-hidden shadow-lg bg-white border border-red-200">
          <div className="px-6 py-4 max-w-xl">
            <div className="font-bold text-xl mb-2">Loading Error</div>
            <p className="text-base">
              Sorry, we're having trouble loading the information you requested.<br />Please wait a few minutes and try again, or contact the financial professional who directed you to this page.
            </p>
          </div>
        </div>
      </div>
    );
}

export default Page_LoadFailed;