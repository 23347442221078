const ContentModule = ({ children, className }) => {
  // Combine the default class with a custom class (if provided)
  const moduleClass = `px-4 xs:px-12 sm:px-2 max-w-screen-lg my-0 mx-auto ${className || ''}`;

  return (
    <div className={moduleClass}>
      {children}
    </div>
  );
};
  
  export default ContentModule;