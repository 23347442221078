import ContentModule from "./ContentModule";
import ModalForm from "./ModalForm";


export const CallToActionModule = ({formData, clientId, personId}) => {

  return (
    <section className="">
      <ContentModule className={"flex flex-col justify-between items-center gap-6"}>
        <h3 className='font-bold text-2xl flex justify-center text-center'>
        Where can we send your completed worksheet?</h3>
        <p className='lg:w-5/6 text-center'>Along with the worksheet, I’ll send my availability to meet so we can review your answers and discuss strategies that have the potential to help you keep more of your hard-earned money in retirement.</p>
        <ModalForm formData={formData} clientId={clientId} personId={personId}/>
      </ContentModule>
    </section>
  );
};
