import { useState } from 'react';


export default function ModalForm({ formData, clientId, personId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);


  const handleSubmit = async (event) => {
    event.preventDefault();

    const cleanData = (data) => {
      // Collect values into an array, ignoring empty strings
      return Object.values(data).filter(value => value !== '');
    };

    const cleanedFormData = {
      taxable: cleanData(formData.taxable),
      taxDeferred: cleanData(formData.taxDeferred),
      taxFree: cleanData(formData.taxFree)
    };
    console.log("CleanedFormData: ", cleanedFormData);

    // Structure the combined data object according to the C# ClientApiInfo class
    const combinedData = {
      AdvisorPersonId: personId,
      ClientId: clientId ?? 0,
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: email,
      PhoneNumber: phone,
      OtherData: cleanedFormData
    };

    // console.log(combinedData);  
    // console.log("Final combinedData before sending:", JSON.stringify(combinedData, null, 2));

    fetch('https://lp-functions.azurewebsites.net/api/pagesubmit/1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(combinedData),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log("Data submitted successfully!", data);
      const baseUrl = 'https://www.rsvpattend.com/taxtrapchallenge/id/';
      const clientIdParam = clientId !== null && clientId !== '' ? `clientId/${clientId}/` : '';
      const url = `${baseUrl}${personId}/${clientIdParam}campaignId/25/skip/No`;
      window.location.href = url;

      handleClose();  // Close only on successful submission
    }).catch(error => {
      console.error("Failed to submit data:", error);
    });
  }

  return (
    <>
      <button onClick={handleOpen} className="px-6 py-4 border rounded bg-green-500 hover:bg-green-700 text-white font-bold shadow-lg hover:shadow-xl transition duration-150 ease-in-out ">
        Request PDF</button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg sm:max-w-[425px] w-full">
            <div className="border-b pb-3">
              <h2 className="text-xl font-semibold ">Get Your Worksheet</h2>
              <p className="text-sm text-gray-600">
                Fill out the form below to receive an emailed copy of your completed PDF.
              </p>
            </div>
            <form className="grid gap-4 py-4" onSubmit={handleSubmit} id="contactForm">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter your first name"
                    required
                    className="px-2 py-1 border rounded w-full"
                  />                </div>
                <div className="space-y-2">
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter your last name"
                    required
                    className="px-2 py-1 border rounded w-full"
                  />

                </div>
              </div>
              <div className="space-y-2">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number"
                  required
                  className="px-2 py-1 border rounded w-full"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="px-2 py-1 border rounded w-full"
                />
              </div>
              <div className="pt-4">
                <button type="button" onClick={handleClose} className="block md:hidden text-gray-400 hover:text-red-600 active:text-red-700 border w-full hover:border-red-500  active:border-red-600 py-2 rounded mb-2">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full text-white rounded py-2 hover:bg-green-600 focus:ring-4 focus:ring-green-500 !bg-green-500">
                  Get PDF
                </button>
              </div>
            </form>
            <button onClick={handleClose} className="absolute hidden md:block top-3 right-3 text-gray-400 hover:text-gray-600 bg-red-500 py-4 px-6 rounded">
              <span className="text-xl">×</span>
            </button>
          </div>
        </div>
      )}
    </>
  );

}


